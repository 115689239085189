<template>
  <div class="nf-flow">
    <div class="title__xl">Fluxo de NFS-e</div>

    <div class="filter-section">
      <b-row>
        <b-col cols="3">
          <div class="form-group">
            <label for="search">Paciente/CPF</label>

            <div class="input-container">
              <Search class="input-icon" />
              <b-input
                autocomplete="off"
                type="text"
                id="search"
                v-model="search"
                class="form-control"
                @input="debounceGetPatients"
                placeholder="Pesquisar paciente"
              />
            </div>
          </div>
        </b-col>

        <b-col cols="3">
          <div class="form-group">
            <label for="nfNumber"> N° da Nota Fiscal</label>

            <validation-provider mode="lazy" name="telefone">
              <b-form-input
                autocomplete="off"
                id="nfNumber"
                type="text"
                @input="() => getNfDataTable()"
                placeholder="00000"
                v-model="nfNumber"
                class="form-control"
                v-mask="['#####']"
              />
            </validation-provider>
          </div>
        </b-col>

        <b-col cols="3">
          <b-form-group id="nfStatus">
            <label for="nfStatus" id="input-title">Situação</label>
            <Autocomplete
              v-model="nfStatusSelected"
              :options="nfStatusList"
              placeholder="Selecionar"
              @input="searchByStatus"
              @generic-cleaner="clearStatus"
            />
          </b-form-group>
        </b-col>

        <b-col cols="3">
          <div class="form-group" @click="chevronRotate">
            <label for="period">Período</label>

            <date-picker
              range
              id="period"
              v-model="period"
              format="DD [de] MMMM"
              :clearable="true"
              :lang="langDatePicker"
              @blur="resetChevronState"
              prefix-class="dx"
              class="dx-datepicker"
              placeholder="Selecione um intervalo"
              @change="updatePeriodSelected"
            >
              <template slot="icon-calendar">
                <div id="chevron">
                  <ChevronDown class="datepicker-chevrondown-icon" />
                </div>
              </template>
            </date-picker>
          </div>
        </b-col>
      </b-row>
    </div>

    <CardsSummary class="mb-3" :dashboard="dashboardData" />

    <NFflowTable @updateTable="() => getNfDataTable()" />

    <div class="pagination-position no-print">
      <b-pagination
        size="sm"
        v-model="page"
        :total-rows="count"
        :per-page="limit"
        first-number
        last-number
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { debounce } from 'lodash'
import api from '@/modules/nfe/api'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  components: {
    Search: () => import('@/assets/icons/search.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Autocomplete: () => import('@/components/Autocomplete'),
    CardsSummary: () => import('@/modules/nfe/components/CardsSummary'),
    NFflowTable: () => import('@/modules/nfe/components/NFflowTable')
  },
  computed: {
    ...mapState('nfe', ['nfTableData'])
  },
  data() {
    return {
      page: 1,
      count: 0,
      limit: 0,
      total: 0,
      search: '',
      chevronState: false,
      dashboardData: null,
      period: [
        new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
      ],
      clinic: getCurrentClinic(),
      langDatePicker: {
        formatLocale: {
          weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
        }
      },
      nfNumber: '',
      nfStatusList: [
        {
          label: 'Processando',
          value: 'processando_autorizacao'
        },
        {
          label: 'Autorizado',
          value: 'autorizado'
        },
        {
          label: 'Erro de Autorização',
          value: 'erro_autorizacao'
        },
        {
          label: 'Cancelado',
          value: 'cancelado'
        }
      ],
      nfStatusSelected: '',
      isLoading: false,
      debounceGetPatients: debounce(this.getNfDataTable, 500),
      debounceGetDashboard: debounce(this.getDashboard, 500),
      debounceGetNfDataTable: debounce(this.getNfDataTable, 500)
    }
  },
  async mounted() {
    await this.debounceGetNfDataTable()
    await this.debounceGetDashboard()
  },
  watch: {
    page(newValue) {
      this.page = newValue
      this.getNfDataTable()
      this.getDashboard()
    }
  },
  methods: {
    chevronRotate() {
      if (this.chevronState === false) {
        const chevron = document.getElementById('chevron')
        chevron.classList.toggle('chevron-active')
        this.chevronState = true
      }
    },
    resetChevronState() {
      if (this.chevronState === true) {
        const chevron = document.getElementById('chevron')
        chevron.classList.toggle('chevron-active')
        this.chevronState = false
      }
    },
    clearStatus() {
      this.nfStatusSelected = ''
      this.debounceGetNfDataTable()
      this.debounceGetDashboard()
    },
    searchByStatus($event) {
      this.nfStatusSelected = $event
      this.debounceGetNfDataTable()
      this.debounceGetDashboard()
    },
    async updatePeriodSelected($event) {
      if ($event[0] === null) {
        const firstDayOfMonth = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          1
        )
        const lastDayOfMounth = new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
        )

        this.period = [firstDayOfMonth, lastDayOfMounth]

        this.getNfDataTable()
        this.getDashboard()
      } else {
        this.period = $event
        this.getNfDataTable()
        this.getDashboard()
      }
    },
    async getNfDataTable() {
      const isLoading = this.$loading.show()

      if (this.nfStatusSelected === undefined) {
        this.nfStatusSelected = ''
      }

      try {
        const { data } = await api.getAllNFs(
          this.clinic.id,
          this.nfStatusSelected,
          this.search,
          this.period[0]?.toJSON(),
          this.period[1]?.toJSON(),
          this.page,
          this.nfNumber
        )

        this.$store.dispatch('nfe/updateNfTableData', data.data)

        this.limit = data.per_page
        this.count = data.total
        this.total = data.total
      } catch (error) {
        console.error(error)
      } finally {
        isLoading.hide()
      }
    },
    async getDashboard() {
      const { data } = await api.getDashboard(
        this.clinic.id,
        this.period[0]?.toJSON(),
        this.period[1]?.toJSON()
      )
      this.dashboardData = data
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/dx-datepicker.scss';

.nf-flow {
  .input-container {
    position: relative;

    .input-icon {
      width: 24px;
      height: 24px;
      stroke: var(--neutral-500);
      margin-right: 8px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 16px;
    }

    .form-control {
      padding: 11px 16px 11px 48px;
    }
  }

  .form-group {
    label {
      display: block;
    }

    div {
      width: 100%;
    }

    .dx-datepicker {
      caret-color: transparent;

      .dx-input-wrapper {
        display: flex;
        justify-content: space-between;
        padding: 2px 4px;
        border: 1px solid var(--neutral-300);
        border-radius: 8px;

        #chevron {
          cursor: pointer;
          transition: all 500ms;
        }

        .datepicker-chevrondown-icon {
          fill: transparent;
          stroke: var(--blue-500);
          height: 24px;
          width: 24px;
        }

        .chevron-active {
          transform: rotate(-180deg);
        }
      }
    }
  }
}
</style>
